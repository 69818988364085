<template>
  <vx-card title="Icons">
    <div class="vx-row">
      <div class="vx-col w-1/4 mt-6" :key="index" v-for="(icon, index) in icons">
        <label class="label-icon">{{icon.class}}</label>
        <img class="icon" :src=icon.url>
      </div>
    </div>
  </vx-card>
</template>

<script>
  export default {
    data() {
      return {
        icons: [
          // {
          //   class: "icon-accordion",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/accordion.svg"},
          // {
          //   class: "icon-button",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/button.svg"},
          // {
          //   class: "icon-card-feature",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/card-feature.svg"},
          // {
          //   class: "icon-card-horizontal",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/card-horizontal.svg"},
          // {
          //   class: "icon-card-practical",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/card-practical.svg"},
          // {
          //   class: "icon-card-simple-horizontal",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/card-simple-horizontal.svg"},
          // {
          //   class: "icon-card-simple",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/card-simple.svg"},
          // {
          //   class: "icon-carousel",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/carousel.svg"},
          // {
          //   class: "icon-col-1",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/col-1.svg"},
          // {
          //   class: "icon-col-2",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/col-2.svg"},
          // {
          //   class: "icon-col-3",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/col-3.svg"},
          // {
          //   class: "icon-col-4",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/col-4.svg"},
          // {
          //   class: "icon-datepicker",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/datepicker.svg"},
          // {
          //   class: "icon-form",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/form.svg"},
          // {
          //   class: "icon-image",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/image.svg"},
          // {
          //   class: "icon-info-box",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/info-box.svg"},
          // {
          //   class: "icon-label",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/label.svg"},
          // {
          //   class: "icon-list-feature",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/list-feature.svg"},
          // {
          //   class: "icon-paragraph",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/paragraph.svg"},
          // {
          //   class: "icon-tables",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/tables.svg"},
          // {
          //   class: "icon-tabs",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/tabs.svg"},
          // {
          //   class: "icon-title-1",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/title-1.svg"},
          // {
          //   class: "icon-title-2",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/title-2.svg"},
          // {
          //   class: "icon-title-3",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/title-3.svg"},
          // {
          //   class: "icon-title-4",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/title-4.svg"},
          // {
          //   class: "icon-title-5",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/title-5.svg"},
          // {
          //   class: "icon-title-6",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/title-6.svg"},
          // {
          //   class: "icon-video-play",
          //   url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/video-play.svg"},
          {
            class: "icon-shield",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/shield.svg"},
          {
            class: "icon-location-pin-outline",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/location-pin-outline.svg"},
          {
            class: "icon-shield-outline",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/shield-outline.svg"},
          {
            class: "icon-arrow-down",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-down.svg"},
          {
            class: "icon-arrow-left",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-left.svg"},
          {
            class: "icon-arrow-right",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-right.svg"},
          {
            class: "icon-arrow-up",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-up.svg"},
          {
            class: "icon-close-circle-line",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/close-circle-line.svg"},
          {
            class: "icon-exclamation-circle-line",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/exclamation-circle-line.svg"},
          {
            class: "icon-question-circle-line",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/question-circle-line.svg"},
          {
            class: "icon-account-voucher",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/account-voucher.svg"},
          {
            class: "icon-account",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/account.svg"},
          {
            class: "icon-airport-circle",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/airport-circle.svg"},
          {
            class: "icon-android",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/android.svg"},
          {
            class: "icon-app-store",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/app-store.svg"},
          {
            class: "icon-apple",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/apple.svg"},
          {
            class: "icon-arrow-circle-down",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-circle-down.svg"},
          {
            class: "icon-arrow-circle-left",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-circle-left.svg"},
          {
            class: "icon-arrow-circle-right",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-circle-right.svg"},
          {
            class: "icon-arrow-circle-up",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/arrow-circle-up.svg"},
          {
            class: "icon-atms",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/atms.svg"},
          {
            class: "icon-attach",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/attach.svg"},
          {
            class: "icon-bar-code",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/bar-code.svg"},
          {
            class: "icon-bill",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/bill.svg"},
          {
            class: "icon-bills",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/bills.svg"},
          {
            class: "icon-briefcase",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/briefcase.svg"},
          {
            class: "icon-building",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/building.svg"},
          {
            class: "icon-buildings",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/buildings.svg"},
          {
            class: "icon-burger",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/burger.svg"},
          {
            class: "icon-calendar",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/calendar.svg"},
          {
            class: "icon-call-center",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/call-center.svg"},
          {
            class: "icon-car-circle",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/car-circle.svg"},
          {
            class: "icon-caret-circle-left",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/caret-circle-left.svg"},
          {
            class: "icon-caret-circle-right",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/caret-circle-right.svg"},
          {
            class: "icon-cash",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/cash.svg"},
          {
            class: "icon-chart-bar-decrease",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/chart-bar-decrease.svg"},
          {
            class: "icon-chart-bar-increase",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/chart-bar-increase.svg"},
          {
            class: "icon-chart-bar-results",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/chart-bar-results.svg"},
          {
            class: "icon-chart-bar",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/chart-bar.svg"},
          {
            class: "icon-check-circle",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/check-circle.svg"},
          {
            class: "icon-close-circle",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/close-circle.svg"},
          {
            class: "icon-collection",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/collection.svg"},
          {
            class: "icon-credit-card-user",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/credit-card-user.svg"},
          {
            class: "icon-credit-card",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/credit-card.svg"},
          {
            class: "icon-date-picker",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/date-picker.svg"},
          {
            class: "icon-Daviplata-charge",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/Daviplata-charge.svg"},
          {
            class: "icon-decrease",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/decrease.svg"},
          {
            class: "icon-desktop",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/desktop.svg"},
          {
            class: "icon-dollar-circle",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/dollar-circle.svg"},
          {
            class: "icon-edit",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/edit.svg"},
          {
            class: "icon-email-send",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/email-send.svg"},
          {
            class: "icon-euro-circle",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/euro-circle.svg"},
          {
            class: "icon-exclamation-circle",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/exclamation-circle.svg"},
          {
            class: "icon-facebook",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/facebook.svg"},
          {
            class: "icon-file-download",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/file-download.svg"},
          {
            class: "icon-file-excel",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/file-excel.svg"},
          {
            class: "icon-file-pdf",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/file-pdf.svg"},
          {
            class: "icon-file-upload",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/file-upload.svg"},
          {
            class: "icon-file-word",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/file-word.svg"},
          {
            class: "icon-file",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/file.svg"},
          {
            class: "icon-financial-document",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/financial-document.svg"},
          {
            class: "icon-financial-movements",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/financial-movements.svg"},
          {
            class: "icon-financial-process",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/financial-process.svg"},
          {
            class: "icon-hand-selector",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/hand-selector.svg"},
          {
            class: "icon-handshake",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/handshake.svg"},
          {
            class: "icon-hashtag",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/hashtag.svg"},
          {
            class: "icon-hotel-circle",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/hotel-circle.svg"},
          {
            class: "icon-increase",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/increase.svg"},
          {
            class: "icon-location-out",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/location-out.svg"},
          {
            class: "icon-location-pin",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/location-pin.svg"},
          {
            class: "icon-location",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/location.svg"},
          {
            class: "icon-lock",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/lock.svg"},
          {
            class: "icon-market",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/market.svg"},
          {
            class: "icon-message-exclamation",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/message-exclamation.svg"},
          {
            class: "icon-message",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/message.svg"},
          {
            class: "icon-mobile",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/mobile.svg"},
          {
            class: "icon-money-bill",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/money-bill.svg"},
          {
            class: "icon-my-votes",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/my-votes.svg"},
          {
            class: "icon-online-center",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/online-center.svg"},
          {
            class: "icon-pause-circle",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/pause-circle.svg"},
          {
            class: "icon-payments",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/payments.svg"},
          {
            class: "icon-paypal",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/paypal.svg"},
          {
            class: "icon-payroll",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/payroll.svg"},
          {
            class: "icon-people",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/people.svg"},
          {
            class: "icon-personal-computer",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/personal-computer.svg"},
          {
            class: "icon-phone",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/phone.svg"},
          {
            class: "icon-play-circle",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/play-circle.svg"},
          {
            class: "icon-pocket-activate",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/pocket-activate.svg"},
          {
            class: "icon-pocket",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/pocket.svg"},
          {
            class: "icon-question",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/question.svg"},
          {
            class: "icon-reservation-process",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/reservation-process.svg"},
          {
            class: "icon-reservation",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/reservation.svg"},
          {
            class: "icon-reward",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/reward.svg"},
          {
            class: "icon-search",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/search.svg"},
          {
            class: "icon-services",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/services.svg"},
          {
            class: "icon-settings-lock",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/settings-lock.svg"},
          {
            class: "icon-settings",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/settings.svg"},
          {
            class: "icon-skype",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/skype.svg"},
          {
            class: "icon-social-network",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/social-network.svg"},
          {
            class: "icon-star",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/star.svg"},
          {
            class: "icon-stop-circle",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/stop-circle.svg"},
          {
            class: "icon-timekeeper",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/timekeeper.svg"},
          {
            class: "icon-transactional-web",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/transactional-web.svg"},
          {
            class: "icon-transactions-international",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/transactions-international.svg"},
          {
            class: "icon-trash",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/trash.svg"},
          {
            class: "icon-twitter",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/twitter.svg"},
          {
            class: "icon-unlock",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/unlock.svg"},
          {
            class: "icon-user-account",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/user-account.svg"},
          {
            class: "icon-user-add",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/user-add.svg"},
          {
            class: "icon-user-card",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/user-card.svg"},
          {
            class: "icon-user-check",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/user-check.svg"},
          {
            class: "icon-user-delet",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/user-delet.svg"},
          {
            class: "icon-user-edit",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/user-edit.svg"},
          {
            class: "icon-user",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/user.svg"},
          {
            class: "icon-users-card",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/users-card.svg"},
          {
            class: "icon-users",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/users.svg"},
          {
            class: "icon-video",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/video.svg"},
          {
            class: "icon-volume-down",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/volume-down.svg"},
          {
            class: "icon-volume-off",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/volume-off.svg"},
          {
            class: "icon-volume-up",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/volume-up.svg"},
          {
            class: "icon-vote",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/vote.svg"},
          {
            class: "icon-wallet",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/wallet.svg"},
          {
            class: "icon-wifi",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/wifi.svg"},
          {
            class: "icon-world",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/world.svg"},
          {
            class: "icon-youtube",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/youtube.svg"},
          {
            class: "icon-zomm-in",
            url:"https://davivienda-resources.s3.amazonaws.com/cms/builder/svgs/all/zomm-in.svg"},
        ]
      }
    }
  };
</script>

<style scoped>
  .vs-table--tbody-table .tr-values.selected {
    cursor: default;
  }

  .label-icon {
    text-align: center;
    display: block;
  }

  .icon {
    width: 40px;
    height: 40px;
    margin: 10px auto;
    display: block;
  }
</style>
